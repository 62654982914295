<template>
  <v-app>
    <login-view 
      :active="active"
      :installed="installed"
      :modal="modal"
      :dev="dev"
    />

    <!-- <login-footer /> -->
  </v-app>
</template>

<script>
  // Utilities
  // import {
  //   sync,
  // } from 'vuex-pathify'

  export default {
    name: 'LayoutLogin',

    props: {
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    components: {
      LoginView: () => import('./View'),
      // LoginFooter: () => import('./Footer'),
    },
  }
</script>
